import React, {useContext, useEffect} from "react";
import {ADMIN, ADMIN_IMAGES, EDITOR, MANAGER, onlineRoles, PRODUCER} from "../../../utils/roles";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {currentFetchedBundleSelector} from "../../../redux/selectors/bundle.selectors";
import {fetchPublicationsOfPeriodical} from "../../../redux/actions/publication.actions";
import {ArticleStatus} from "../../../types/article";
import {setArticleDialogMode} from "../../../redux/actions/article/article-dialog.actions";
import {ArticleDialogMode} from "../../../redux/reducers/article/article-dialog.reducer";
import {uuidv4} from "../../../utils/uuid";
import {dispatchArticleAction} from "../../../redux/actions/workflow.actions";
import {ArticleActionName} from "../../../types/workflow";
import {PageSize, SearchContext} from "../../../types/common-search";
import {forceBundleOptions} from "../../../utils/publication-utils";
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors";
import {State} from "../../../redux/reducers";
import axios, {AxiosResponse} from "axios";
import {downloadArticle} from "../../../utils/common-utils";
import {notifyError} from "../../../redux/actions/notification.actions";
import {deleteArticle, pushArticlesOnlineResponseAction} from "../../../redux/actions/article/article.actions";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {Grid, IconButton, Tooltip} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {ViewIcon} from "../../../icon";
import Auth, {isAuth} from "../../Auth";
import Hamburtton, {HamburttonProps} from "../../commons/Hamburtton";
import {DeleteArticleDialogContext} from "../../commons/context/DeleteArticleDialogContext";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import PlacardStatusAndDownload from "../../publications/workflow/PlacardStatusAndDownload";
import PushOnlineArticleDialog from "../../articles/actions/PushOnlineArticleDialog";


type Props = {
    page: number
    size: PageSize
    queryId: string
    attachedOnly: boolean
}

export default function BundleOrganizeRightHeader({page, size, queryId, attachedOnly}: Props) {
    const bundle = useSelector(currentFetchedBundleSelector)
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')
    const roles = useSelector(({auth}: State) => auth?.roles ?? []);
    const history = useHistory()
    const {
        openConfirmationDialog,
        setArticleBeingDeleted,
        articleBeingDeleted,
        confirmationDialogOpen
    } = useContext(DeleteArticleDialogContext)

    useEffect(() => {
            if (bundle) {
                dispatch(fetchPublicationsOfPeriodical(bundle.periodical))
            }
        }, [bundle?.id]
    )

    const publication = useSelector(getCurrentPublication)

    const openDeleteArticle = () => {
        if (bundle) {
            setArticleBeingDeleted({id: bundle.id, isBundle: true})
            openConfirmationDialog(true)
        }
    }
    const handleArticleDeletion = () => {
        dispatch(deleteArticle(articleBeingDeleted))
        history.push(`/${t('routes.article.root')}`)
    }

    const downloadXml = (id: string) => {
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        axios.get(`/api/bundle/xml/${id}`, {headers})
            .then((r: AxiosResponse) => downloadArticle(`${id}.xml`, r.data as string))
            .catch((err: any) => {
                dispatch(notifyError(+err.response.status, {type: 'BUNDLE'}))
            })
    }

    const bap = () => {
        if (bundle) {
            const uuid = uuidv4();
            dispatch(dispatchArticleAction({
                id: bundle.id,
                action: ArticleActionName.BAP_ARTICLE,
                uuid,
                context: SearchContext.BUNDLE_ARTICLE,
                forceOptions: forceBundleOptions(false, bundle, attachedOnly, publication, queryId, size, page)
            }))
        }
    }

    const unlockPlacard = () => {
        if (bundle) {
            const uuid = uuidv4();
            dispatch(dispatchArticleAction({
                id: bundle.id,
                action: ArticleActionName.UNLOCK_ARTICLE_PLACARD,
                uuid,
                context: SearchContext.BUNDLE_ARTICLE,
                forceOptions: forceBundleOptions(false, bundle, attachedOnly, publication, queryId, size, page)
            }))
        }
    }

    const placard = () => {
        if (bundle) {
            const uuid = uuidv4();
            dispatch(dispatchArticleAction({
                id: bundle.id,
                action: ArticleActionName.GENERATE_ARTICLE_PLACARD,
                uuid,
            }))
        }
    }

    const addArticle = () => {
        if (bundle) {
            dispatch(setArticleDialogMode({
                mode: ArticleDialogMode.CREATE,
                default: {
                    bundleId: bundle.id,
                    periodicalId: bundle.periodical,
                    publicationId: bundle.publication,
                    planNodeId: bundle.planNode?.id,
                    type: undefined,
                    isBundle: false
                }
            }))
        }
    }

    const navToAdminBundle = () => {
        if (bundle && bundle.id) {
            history.push(`/${t("routes.admin.root")}/${t('routes.admin.bundle.custom')}/${t('routes.admin.bundle.edit')}/${bundle.id}`);
        }
    }

    const navToBundleEditInFonto = () => {
        if (bundle && bundle.id) {
            history.push(`/${t('routes.bundle.root')}/${bundle.id}/${t('routes.article.edit')}`)
        }
    }

    const hamburttonProps: HamburttonProps = {
        listButtonAction: [
            {
                action: navToBundleEditInFonto,
                label: t('bundles.edit'),
                display: (bundle && ((
                    bundle.status === ArticleStatus.IN_PROGRESS
                    || bundle.status === ArticleStatus.READY) ||
                    (bundle.status === ArticleStatus.PUBLISHED && isAuth(roles, [ADMIN], bundle.periodical))))
            }
        ],
        buttonAction: bundle && bundle.status === ArticleStatus.IN_PROGRESS && bundle.isValid ? {
            label: t('articles.bap'),
            action: () => bap()
        } : undefined,

        actions: [
            {
                label: t('workflows.generate_placard'),
                action: placard,
                display:
                    bundle
                    && (bundle.status === ArticleStatus.IN_PROGRESS || bundle.status === ArticleStatus.READY)
                    && (bundle.lastRequestPlacardProcessStatus === null || bundle.lastRequestPlacardProcessStatus === 'CANCEL')
            },
            {
                label: t('articles.push_online'),
                action: () => dispatch(pushArticlesOnlineResponseAction(bundle ? [bundle.id] : undefined)),
                display: bundle && bundle.status === ArticleStatus.READY && !bundle.nextPublicationOnlineDate &&
                    isAuth(roles, onlineRoles, bundle.periodical)
            },
            {
                label: t('bundles.add_article'),
                action: () => addArticle(),
                display: bundle && bundle.status !== ArticleStatus.PUBLISHING && bundle.status !== ArticleStatus.PUBLISHED && isAuth(roles, [ADMIN, MANAGER, EDITOR])
            },
            {
                label: t('bundles.download'),
                action: () => bundle ? downloadXml(bundle.id) : undefined
            },
            {
                label: t('bundles.delete'),
                action: openDeleteArticle,
                display: isAuth(roles, [ADMIN], undefined)
                    && bundle && bundle.publication === null
                    && bundle.publicationOnlineDate === null
            },
            {
                label: t('workflows.unlock_placard'),
                action: unlockPlacard,
                display: isAuth(roles, [ADMIN], undefined) && bundle && (bundle.lastRequestPlacardProcess !== null && bundle.lastRequestPlacardProcess !== 'CANCEL')
            },
            {
                label: t('bundles.edit_custom_bundle'),
                action: navToAdminBundle,
                display: isAuth(roles, [ADMIN, MANAGER, EDITOR], undefined) && bundle &&
                    bundle.status !== ArticleStatus.PUBLISHING && bundle.status !== ArticleStatus.PUBLISHED
            }]
    }
    const requiredRoles = [ADMIN, ADMIN_IMAGES, EDITOR, MANAGER, PRODUCER]

    return <>
        <ConfirmationDialog
            title={t(articleBeingDeleted.isBundle ? 'bundles.delete_confirmation' : 'articles.delete_confirmation')}
            description={''}
            actions={[
                {label: t('dialogs.cancel'), color: 'default', action: () => openConfirmationDialog(false)},
                {label: t('dialogs.confirm'), action: handleArticleDeletion, variant: 'contained'}
            ]}
            isOpened={confirmationDialogOpen}
            onClose={() => openConfirmationDialog(false)}
        />

        {bundle && <PushOnlineArticleDialog ids={[bundle.id]}/>}

        {bundle &&
        <Grid
            container
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
        >
            <Grid style={{marginTop: 16}} container direction="row"
                  justify="flex-end">
                <Link
                    to={`/${t('routes.bundle.root')}/${bundle.id}`}
                    component={RouterLink}>
                    <Tooltip title={`${t('bundles.see_bundle')}` || ""}>
                        <IconButton>
                            <ViewIcon/>
                        </IconButton>
                    </Tooltip>
                </Link>
                <PlacardStatusAndDownload id={bundle.id} type={"article"} size={"medium"}
                                          lastAvailablePlacardProcessStatus={bundle.lastAvailablePlacardProcessStatus}/>
            </Grid>

            <Auth requiredRoles={requiredRoles} periodicalRestriction={bundle.periodical}>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                        {hamburttonProps && <Hamburtton {...hamburttonProps}/>}
                    </Grid>
                </Grid>
            </Auth>
        </Grid>}
    </>
}
