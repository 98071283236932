import {IconButton} from "@material-ui/core";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {ElementContext, Filters, SearchContext} from "../../../types/common-search";
import Tooltip from "@material-ui/core/Tooltip";
import {GeneratePdfIcon} from "../../commons/icon/icons";
import {ArticleActionName} from "../../../types/workflow";
import {dispatchArticleAction, dispatchMultipleArticlesAction} from "../../../redux/actions/workflow.actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {uuidv4} from "../../../utils/uuid";
import {processInProgress} from "../../../redux/selectors/workflow.selectors";
import Auth from "../../Auth";
import {ADMIN, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import {ArticleStatus} from "../../../types/article";
import {BundleResponse} from "../../../types/bundle";
import { PdfBundleProps } from "../../GeneratePlacardButton";



function PdfBundle({bundle, ids, context, forceOptions, size, buttonClassName}: PdfBundleProps) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const processInProgressSelector = useSelector(processInProgress)
    const [currentProcess, setCurrentProcess] = useState<string>();

    const displayLoading = () => {
        if (currentProcess && processInProgressSelector.includes(currentProcess)) {
            return <CircularProgress color="inherit" size={size && size == 'small' ? 10 : 15}
                                     style={size === undefined || size === 'medium' ? {marginTop: 15} : {}}/>
        } else {
            return <IconButton
                size="small"
                className={buttonClassName}
                onClick={() => {
                    if (bundle) {
                        generatePDF(bundle.id!!)
                    } else {
                        generatePDFs()
                    }
                }}>
                <GeneratePdfIcon/>
            </IconButton>
        }

    }

    const generatePDFs = () => {
        const uuid = uuidv4();
        dispatch(dispatchMultipleArticlesAction({
            ids: ids!!,
            action: ArticleActionName.GENERATE_ARTICLE_PLACARD,
            uuid
        }))
        setCurrentProcess(uuid)
    }

    const generatePDF = (bundle: string) => {
        const uuid = uuidv4();

        dispatch(dispatchArticleAction({
            id: bundle,
            action: ArticleActionName.GENERATE_ARTICLE_PLACARD,
            uuid,
            context,
            forceOptions
        }))
        setCurrentProcess(uuid)

    }

    if (!bundle || (bundle && (bundle.status === ArticleStatus.IN_PROGRESS || bundle.status === ArticleStatus.READY)
        && (bundle.lastRequestPlacardProcessStatus === null || bundle.lastRequestPlacardProcessStatus === undefined || bundle.lastRequestPlacardProcessStatus === 'CANCEL'))) {
        return (
            <Auth requiredRoles={[ADMIN, PRODUCER, MANAGER, EDITOR]}>
                <Tooltip
                    title={t('workflows.generate_placard') ?? ''}>
                    {displayLoading()}
                </Tooltip>
            </Auth>
        )
    } else {
        return (<></>)
    }
}

export default PdfBundle;
