import React, {useCallback, useContext, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Grid, IconButton, Paper, Tooltip, Typography} from '@material-ui/core'
import {useDispatch, useSelector} from "react-redux";
import {fetchBundle} from "../../../redux/actions/bundle.actions";
import {currentFetchedBundleSelector} from "../../../redux/selectors/bundle.selectors";
import {useTranslation} from "react-i18next";
import axios, {AxiosResponse} from "axios";
import {downloadArticle} from "../../../utils/common-utils";
import {notifyError} from "../../../redux/actions/notification.actions";
import {State} from "../../../redux/reducers";
import BundleStatusList from "./BundleStatusList";
import InformationPanel from "../../commons/InformationPanel";
import DateTime from "../../commons/DateTime";
import Auth from "../../Auth";
import {ADMIN, ADMIN_ARTICLES, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import {
    DeleteIcon,
    DownloadIcon,
    MoveIcon,
    SetFrontCoverIcon,
    ShowXmlIcon,
    UnsetFrontCoverIcon
} from "../../commons/icon/icons";
import {DeleteArticleDialogContext} from "../../commons/context/DeleteArticleDialogContext";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import {deleteArticle, toggleArticleCover} from "../../../redux/actions/article/article.actions";
import {ArticleDialogMode} from "../../../redux/reducers/article/article-dialog.reducer";
import {fetchPublicationsOfPeriodical} from "../../../redux/actions/publication.actions";
import {OpenArticleDialogPayload, setArticleDialogMode} from "../../../redux/actions/article/article-dialog.actions";
import EditBundle from "../actions/EditBundle";
import BapBundle from "../actions/BapBundle";
import RevertBapBundle from "../actions/RevertBapBundle";
import {BundleResponse} from "../../../types/bundle";
import UnlockPdfArticle from "../../articles/actions/UnlockPdfArticle";
import OrganizeBundle from "../../articles/actions/OrganizeBundle";
import {ArticleStatus, fixedAttachments} from "../../../types/article";
import PostAddIcon from "@material-ui/icons/PostAdd";
import PushOnlineArticle from "../../articles/actions/PushOnlineArticle";
import {SearchContext} from "../../../types/common-search";
import { GeneratePlacardButton } from "../../GeneratePlacardButton";

export default function BundleViewRightPanel() {
    const {id} = useParams() as { id: string }
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {
        openConfirmationDialog,
        setArticleBeingDeleted,
        articleBeingDeleted,
        confirmationDialogOpen
    } = useContext(DeleteArticleDialogContext)
    const bundle = useSelector(currentFetchedBundleSelector)

    const openBundleDialog = (bundle, mode: ArticleDialogMode) => {
        dispatch(fetchPublicationsOfPeriodical(bundle.periodical))
        dispatch(setArticleDialogMode({
            mode: mode,
            default: bundle,
            source: mode === ArticleDialogMode.MOVE ? id : undefined
        } as OpenArticleDialogPayload))
    }

    useEffect(() => {
        dispatch(fetchBundle(id))
    }, [id])

    const handleArticleDeletion = () => {
        dispatch(deleteArticle({...articleBeingDeleted, redirectUrl: t('routes.article.root')}))
    }

    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')
    const downloadXml = (id: string) => {
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        axios.get(`/api/bundle/xml/${id}`, {headers})
            .then((r: AxiosResponse) => downloadArticle(`${id}.xml`, r.data as string))
            .catch((err: any) => {
                dispatch(notifyError(+err.response.status, {type: 'BUNDLE'}))
            })
    }

    const openXmlInNewTab = (id: string) => {
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        axios.get(`/api/bundle/xml/${id}`, {headers})
            .then((r: AxiosResponse) => {
                let blob = new Blob([r.data], {type: 'text/xml'})
                let url = URL.createObjectURL(blob)
                window.open(url)
                URL.revokeObjectURL(url)
            })
            .catch((err: any) => {
                dispatch(notifyError(+err.response.status, {type: 'BUNDLE'}))
            })
    }

    const handleToggleArticleCover = (bundle: BundleResponse, flag: boolean) => dispatch(toggleArticleCover({
        article: {id: bundle.id, isBundle: true},
        flag
    }))

    const handleAddArticleInBundle = useCallback(() => {
        if (bundle) {
            dispatch(fetchPublicationsOfPeriodical(bundle.periodical))
            dispatch(setArticleDialogMode({
                mode: ArticleDialogMode.CREATE,
                default: {
                    bundleId: bundle.id,
                    periodicalId: bundle.periodical,
                    publicationId: bundle.publication,
                    planNodeId: bundle.planNode?.id,
                    type: undefined,
                    isBundle: false
                }
            }))
        }
    }, [bundle])

    return <>
        {bundle && <Paper>
            <ConfirmationDialog
                title={t('bundles.delete_confirmation')}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default'},
                    {label: t('dialogs.confirm'), action: handleArticleDeletion, variant: 'contained'}
                ]}
                isOpened={confirmationDialogOpen}
                onClose={() => openConfirmationDialog(false)}
            />

            {/* Status */}
            <Grid container direction={"column"} style={{padding: 8}} spacing={1}>
                <Grid item>
                    <Typography variant={"h6"}>{t('articles.status.name')}</Typography>
                </Grid>

                <Grid container spacing={1} alignItems={"center"} direction={"row"} style={{marginLeft: 4}}>
                    <BundleStatusList bundle={bundle} justify={"flex-start"}/>
                </Grid>

                {/* Actions */}
                <Auth requiredRoles={[ADMIN, MANAGER, EDITOR, PRODUCER]}
                      periodicalRestriction={bundle.periodical}>
                    <Grid item>
                        <Typography variant={"h6"}>{t('misc.actions')}</Typography>
                    </Grid>

                    <Grid container spacing={1} alignItems={"center"} direction={"row"} style={{marginLeft: 4}}>
                        <EditBundle bundle={bundle} size="small"/>

                        <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, EDITOR, MANAGER]}
                              periodicalRestriction={bundle?.periodical}>
                            <Grid item>
                                <OrganizeBundle document={bundle} size="small"/>
                            </Grid>
                        </Auth>

                        <BapBundle bundle={bundle} size="small"/>

                        <RevertBapBundle bundle={bundle} size="small"/>

                        <GeneratePlacardButton pdfBundle={{bundle, size: 'small'}} />

                        <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, MANAGER, EDITOR]}
                              periodicalRestriction={bundle.periodical}>
                            <>
                                {(!bundle.isFrontCover) &&
                                <Grid item>
                                    <Tooltip
                                        title={t('misc.set_front_cover') ?? ''}>
                                        <IconButton size="small"
                                                    onClick={() => handleToggleArticleCover(bundle, true)}
                                        >
                                            <SetFrontCoverIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>}

                                {bundle.isFrontCover &&
                                <Grid item>
                                    <Tooltip
                                        title={t('misc.unset_front_cover') ?? ''}>
                                        <IconButton size="small"
                                                    onClick={() => handleToggleArticleCover(bundle, false)}
                                        >
                                            <UnsetFrontCoverIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>}
                            </>
                        </Auth>

                        {(bundle.lastRequestPlacardProcessStatus !== null && bundle.lastRequestPlacardProcessStatus !== undefined && bundle.lastRequestPlacardProcessStatus !== 'CANCEL') &&
                        <Grid item>
                            <UnlockPdfArticle documentId={bundle.id} size="small"/>
                        </Grid>}

                        <Auth requiredRoles={[ADMIN, MANAGER, EDITOR, PRODUCER]}
                              periodicalRestriction={bundle.periodical}>
                            <Grid item>
                                <Tooltip title={t('misc.download') ?? ""}>
                                    <IconButton size={"small"} onClick={() => downloadXml(bundle.id ?? "")}>
                                        <DownloadIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Auth>

                        <Grid item>
                            <Tooltip title={t('bundles.see_xml') ?? ""}>
                                <IconButton size={"small"}
                                            onClick={() => openXmlInNewTab(bundle.id ?? "")}>
                                    <ShowXmlIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        {(!fixedAttachments.includes(bundle.attached) && [ArticleStatus.IN_PROGRESS, ArticleStatus.READY].includes(bundle.status)) &&
                        <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, EDITOR, MANAGER]}
                              periodicalRestriction={bundle.periodical}>
                            <Grid item>
                                <Tooltip title={t('bundles.move') ?? ""}>
                                    <IconButton
                                        size={"small"} onClick={() => {
                                        openBundleDialog({
                                            ...bundle,
                                            periodicalId: bundle.periodical,
                                            publicationId: bundle.publication,
                                            isBundle: true
                                        }, ArticleDialogMode.MOVE)
                                    }}>
                                        <MoveIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Auth>
                        }
                        <PushOnlineArticle bundle={bundle}/>

                        {(!fixedAttachments.includes(bundle.attached) && bundle.publicationOnlineDate === null) &&
                        <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, EDITOR, MANAGER, PRODUCER]}>
                            <Grid item>
                                <Tooltip title={t('bundles.delete') ?? ""}>
                                    <IconButton
                                        size={"small"} onClick={() => {
                                        setArticleBeingDeleted({id: bundle.id, isBundle: true})
                                        openConfirmationDialog(true)
                                    }}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Auth>
                        }

                        {(bundle.status === ArticleStatus.IN_PROGRESS || bundle.status === ArticleStatus.READY) && bundle.lastRequestPlacardProcessStatus === null
                        && <Auth requiredRoles={[ADMIN, MANAGER, EDITOR]}>
                            <Grid item>
                                <Tooltip
                                    title={t('bundles.add_article') ?? ''}>
                                    <IconButton
                                        onClick={handleAddArticleInBundle} size="small">
                                        <PostAddIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Auth>}
                    </Grid>
                </Auth>

                {/* Informations */}
                <Grid item>
                    <Typography variant={"h6"}>{t('misc.informations')}</Typography>
                </Grid>
                <Grid container spacing={1} alignItems={"center"} direction={"row"} style={{marginLeft: 4}}>
                    <InformationPanel
                        disableMargin={true}
                        width={12}
                        hideDivider={true}
                        rows={[
                            {label: t('misc.id'), show: true, value: bundle.id},
                            {
                                label: t('articles.status.name'),
                                show: true,
                                value: t(`articles.status.${bundle.status}`)
                            },
                            {
                                label: t('misc.creation_date'),
                                show: bundle.creationDate !== "" && bundle.creationDate !== null,
                                value: <DateTime date={bundle.creationDate ?? ""}/>
                            },
                            {
                                label: t('misc.creation_by'),
                                show: true,
                                value: bundle.creationUser
                            },
                            {
                                label: t('misc.last_modification_date'),
                                show: bundle.modificationDate !== "" && bundle.modificationDate !== null,
                                value: <DateTime date={bundle.modificationDate ?? ""}/>
                            },
                            {
                                label: t('misc.last_modification_by'),
                                show: true,
                                value: bundle.modificationUser
                            },
                            {
                                label: t('misc.front_cover'),
                                show: true,
                                value: t(`misc.boolean.${bundle.isFrontCover}`)
                            },
                            {
                                label: t('misc.importance'),
                                show: true,
                                value: t(`misc.boolean.${bundle.isImportant}`)
                            },
                            {
                                label: t('misc.type'),
                                show: true,
                                value: t(`enums.articles.type.${bundle.type}.main`)
                            },
                            {
                                label: t('misc.plan_node'),
                                show: bundle.planNode?.name !== null,
                                value: bundle.planNode?.name
                            },
                            {
                                label: t('articles.character_volume'),
                                show: true,
                                value: t('articles.character_volume_value', {count: bundle.charVolume})
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </Paper>}
    </>
}
