import { DateTime } from "luxon";
import search from "../../redux/reducers/search";

export enum DocumentTypeEnum {
  LEXPRESS = "LEXPRESS",
  GDA = "GDA",
  OUVRAGE = "OUVRAGE",
  OXYGEN = "OXYGEN",
}

export const displayDocumentTypeEnum = (type: DocumentTypeEnum) => {
  switch (type) {
    case DocumentTypeEnum.LEXPRESS:
      return "Lex-press";
    case DocumentTypeEnum.GDA:
      return "GDA";
    case DocumentTypeEnum.OUVRAGE:
      return "Ouvrage";
    case DocumentTypeEnum.OXYGEN:
      return "Oxygen";
  }
};

export enum DocumentStatusEnum {
  ATTACHED = "ATTACHED",
  NOT_ATTACHED = "NOT_ATTACHED",
}

export const displayDocumentStatusEnum = (type: DocumentStatusEnum) => {
  switch (type) {
    case DocumentStatusEnum.ATTACHED:
      return "Rattaché";
    case DocumentStatusEnum.NOT_ATTACHED:
      return "Non rattaché";
  }
};
export enum DocumentNatureEnum {
  ACTE = "ACTE",
  CLAUSE = "CLAUSE",
  LOT = "LOT",
  MODELE = "MODELE",
}

export const displayDocumentNatureEnum = (type: DocumentNatureEnum) => {
  switch (type) {
    case DocumentNatureEnum.ACTE:
      return "Actes";
    case DocumentNatureEnum.CLAUSE:
      return "Clauses";
    case DocumentNatureEnum.LOT:
      return "Lot";
    case DocumentNatureEnum.MODELE:
        return "Modèles";
  }
};

export const displayOxygenTypeEnum = (type: OxygenTypeEnum) => {
  switch (type) {
    case OxygenTypeEnum.ETUDE:
      return "Études";
    case OxygenTypeEnum.FORMULE:
      return "Formules";
  }
};

export const displayOxygenCategoryEnum = (type: OxygenCategoryEnum) => {
  switch (type) {
    case OxygenCategoryEnum.BOURSE:
      return "Bourse";
    case OxygenCategoryEnum.OTHER:
      return "Autre";
    case OxygenCategoryEnum.TRAVAIL:
      return "Travail";
    case OxygenCategoryEnum.SOCIETE:
      return "Sociétés";
  }
};

export enum OxygenTypeEnum {
  FORMULE = "FORMULE",
  ETUDE = "ETUDE",
}

export enum OxygenCategoryEnum {
  SOCIETE = "SOCIETE",
  BOURSE = "BOURSE",
  OTHER = "AUTRE",
  TRAVAIL = "TRAVAIL",
}

export interface DateRange {
  start?: DateTime;
  end?: DateTime;
}

export interface Search {
  treeId: number;
  searchText: string;
  type?: DocumentTypeEnum;
  status: DocumentStatusEnum[];
  periodicalFilter?: string[]; // Revue
  planNodeFilter?: string[]; // Rubrique
  publicationIdFilter?: number[]; // Parution
  publicationDateFilter?: DateRange;
  nature: DocumentNatureEnum[];
  ean?: string;
  oxygenType?: OxygenTypeEnum;
  oxygenCategory: OxygenCategoryEnum[];
  typeActe?: string[];
  thematic?: string[];
}

export const setPublicationFilter = (publications: number[]) => (search: Search): Search => ({
  ...search,
  publicationIdFilter: [...publications]
})

export const setEan = (ean?: string) => (search: Search): Search => ({
  ...search,
  ean
});

export const setOxygenTypeActe =
  (typeActe: string[]) =>
  (search: Search): Search => ({
    ...search,
    typeActe,
  });

export const setOxygenThematics =
  (thematic: string[]) =>
  (search: Search): Search => ({
    ...search,
    thematic,
  });

// Refacto avec setStatus et setDocumentNature
export const setOxygenCategoryFromString =
  (checked: boolean, text: string) =>
  (search: Search): Search => {
    if (
      !Object.values(OxygenCategoryEnum).includes(text as OxygenCategoryEnum)
    ) {
      throw new Error("Invalid Oxygen Category value provided");
    }
    const category = text as OxygenCategoryEnum;
    if (checked) {
      const set = new Set(search.oxygenCategory);
      set.add(category);
      return { ...search, oxygenCategory: Array.from(set) };
    } else {
      return {
        ...search,
        oxygenCategory: search.oxygenCategory.filter((e) => e !== category),
      };
    }
  };

export const setOxygenTypeFromString =
  (text: string) =>
  (search: Search): Search => {
    if (!Object.values(OxygenTypeEnum).includes(text as OxygenTypeEnum)) {
      throw new Error("Invalid Oxygen Type value provided");
    }

    const type = text as OxygenTypeEnum;
    return { ...search, oxygenType: type };
  };

export const setPublicationDateFilterFromString =
  (date: { start?: string; end?: string }) => (search: Search) =>
    ({
      ...search,
      publicationDateFilter: {
        start: date.start
          ? DateTime.fromISO(date.start).toISODate()
          : undefined,
        end: date.end ? DateTime.fromISO(date.end).toISODate() : undefined,
      },
    } as Search);

export const setPeriodicalFilter =
  (periodical: string[]) => (search: Search) => ({
    ...search,
    periodicalFilter: periodical,
  });

export const setPlanNodeFilter = (plan: string[]) => (search: Search) => ({
  ...search,
  planNodeFilter: plan,
});

export const setType = (type: DocumentTypeEnum) => (search: Search) => ({
  ...search,
  type: type,
});
export const setTypeFromString = (text: string) => (search: Search) => {
  if (!Object.values(DocumentTypeEnum).includes(text as DocumentTypeEnum)) {
    throw new Error("Invalid Document Type value provided");
  }
  const type = text as DocumentTypeEnum;
  return { ...search, type: type };
};
export const setSearchText = (text: string) => (search: Search) => ({
  ...search,
  searchText: text,
});

export const setStatusFromString =
  (checked: boolean, text: string) => (search: Search) => {
    if (
      !Object.values(DocumentStatusEnum).includes(text as DocumentStatusEnum)
    ) {
      throw new Error("Invalid Document Status value provided");
    }
    const status = text as unknown as DocumentStatusEnum;
    if (checked) {
      const set = new Set(search.status);
      set.add(status);
      return { ...search, status: Array.from(set) };
    } else {
      return { ...search, status: search.status.filter((e) => e !== status) };
    }
  };

export const setDocumentNatureFromString =
  (checked: boolean, text: string) => (search: Search) => {
    if (
      !Object.values(DocumentNatureEnum).includes(text as DocumentNatureEnum)
    ) {
      throw new Error("Invalid Document Nature value provided");
    }
    const nature = text as unknown as DocumentNatureEnum;

    if (checked) {
      const set = new Set(search.nature);
      set.add(nature);
      return { ...search, nature: Array.from(set) };
    } else {
      return { ...search, nature: search.nature.filter((e) => e !== nature) };
    }
  };

export const resetFilter = (search: Search): Search => ({
  ...search,
  ean: undefined,
  nature: [],
  oxygenCategory: [],
  oxygenType: undefined,
  periodicalFilter: undefined,
  planNodeFilter: undefined,
  publicationDateFilter: undefined,
  searchText: "",
  status: [],
  thematic: undefined,
  typeActe: undefined
}) 

export const defaultSearch: Search = {
  treeId: 1,
  searchText: "",
  status: [],
  nature: [],
  oxygenCategory: [],
};
